<template>

<div class="extra" :class="{'is-active': selected}">

	<div class="extra-icon" v-if="!selected"><app-icon :icon="item.icon" /></div>
	<div class="extra-icon" v-if="selected"><app-icon icon="tick" /></div>

	<div class="extra-about">
		
		<div class="extra-about-name">{{ item.name }}</div>
		<div class="extra-about-text">{{ item.text }}</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['item', 'selected']

}

</script>

<style scoped>

.extra {
	width: 100%;
	padding: 10px;
	border: 1px solid #1e3054;
	border-radius: 10px;
	display: flex;
	transition: transform 100ms linear;
	align-items: center;
	margin-bottom: 20px;
}

.extra:last-child {
	margin-bottom: 0px;
}

.is-mobile .extra {
	flex-direction: column;
}

.extra.is-active {
	background-color: #ffbe7a;
	border-color: #ffbe7a;
}

.extra-icon {
	font-size: 32px;
	line-height: 48px;
	text-align: center;
	width: 48px;
	flex-shrink: 0;
	color: #1e3054;
}

.extra.is-active .extra-icon {
	color: #fff;
}

.extra-about {
	padding: 0px 20px;
	flex-grow: 1;
}

.is-mobile .extra-about {
	padding: 20px 0px;
	text-align: center;
}

.extra.is-active .extra-about {
	color: #fff;
}

.extra-about-name {
	font-size: 20px;
	font-weight: 400;
}

.extra-about-text {
	font-size: 14px;
	line-height: 18px;
	margin-top: 2px;
	font-weight: 300;
}

.extra-fee {
	font-size: 20px;
	color: #333;
	font-weight: 400;
	width: 200px;
	flex-shrink: 0;
	padding-left: 40px;
}

.extra.is-active .extra-fee {
	color: #fff;
}

</style>